<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Modifica certificazione {{ certification ? certification.name : '' }}</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'inventory.certifications' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <CertificationForm v-if="certification" :value="certification" :feedback.sync="feedback" @submitForm="saveCertification" edit />
    </div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import CertificationForm from '@/views/components/Form/CertificationForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    CertificationForm,
  },
  data () {
    return {
      certification: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'inventory.certifications' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveCertification (certificationForm) {
      const certification = { ...certificationForm };

      this.$api.updateCertification(certification.id, certification)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    $api.fetchCertification(to.params.id)
      .then(res => {
        return next(vm => {
          vm.certification = res?.data || {};
        });
      })
      .catch(err => {
        return next(vm => vm.$log.error(err));
      })
    ;
  },
};

</script>
